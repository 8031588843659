import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alumini',
  templateUrl: './alumini.component.html',
  styleUrls: ['./alumini.component.css']
})
export class AluminiComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
