import { Component,EventEmitter, OnInit, HostListener } from '@angular/core';
import{AppServiceService} from '../app-service.service';
var player;
var done = false;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
public news = [
  { title:"Acknowledgement Of Outstanding Performance",newsId:'newsid1'},
  { title:"Cash Awards For Academic Excellence",newsId:'newsid3'},
  { title:"Quiz Competition",newsId:'newsid4'},
  { title:"Annual Cultural Activities",newsId:'newsid5'},
  { title:"Stalls At Annual Fest", newsId:'newsid6'}
];
@HostListener('window:keyup', ['$event'])
	keyEvent(event: KeyboardEvent) {
     if (event.keyCode == 27) {
      
     }
  }
  constructor(private service:AppServiceService) { }

  ngOnInit() {
  }
  


goTonews(newsid){

}

}
